import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./App.css"; // Assuming you have some basic styling here
import { Link as RouterLink, useNavigate } from "react-router-dom";
import env from "react-dotenv";

import Hero from "./assets/hero.png";
import Title from "./assets/title.png";

const raceMap = {
  1: "Human",
  2: "Orc",
  3: "Dwarf",
  4: "Night Elf",
  5: "Undead",
  6: "Tauren",
  7: "Gnome",
  8: "Troll",
  10: "Blood Elf",
  11: "Draenei",
};

const classMap = {
  1: "Warrior",
  2: "Paladin",
  3: "Hunter",
  4: "Rogue",
  5: "Priest",
  6: "Death Knight",
  7: "Shaman",
  8: "Mage",
  9: "Warlock",
  11: "Druid",
};

const features = [
  { name: "XP Rates", status: "1x" },
  { name: "Autobalance", status: "✓" },
  { name: "Challenge Modes", status: "✓" },
  { name: "Auto Learn Spells on Levelup", status: "✓" },
  { name: "Custom Lua Scripts", status: "✓" },
  { name: "Enchanter NPC", status: "✓" },
  { name: "Solo LFG", status: "✓" },
  { name: "Transmog", status: "✓" },
  { name: "Regular Trivia for Rewards", status: "✓" },
  { name: "Unlimited Ammo", status: "✓" },
  { name: "Prestige System", status: "✓" },
  { name: "EXP Bank", status: "✓" },
  { name: "Working player bots", status: "✓" },
  { name: "Working BGs", status: "✓" },
];

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [onlineCharacters, setOnlineCharacters] = useState([]);
  const [email, setEmail] = useState("");
  const [accountName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("account") && sessionStorage.getItem("verifier")) {
      setIsLoggedIn(true);
    }

    const fetchOnlineCharacters = async () => {
      try {
        let response = await fetch("/api/world/online");

        if (response.status == 404 || response.status == 304) {
          response = await fetch("http://localhost:4000/world/online");
        }

        const data = await response.json();
        const characters = data.characters.map((char) => ({
          name: char.name,
          level: char.level,
          race: raceMap[char.race],
          class: classMap[char.class],
        }));
        setOnlineCharacters(characters);
      } catch (error) {
        console.error("Error fetching online characters:", error);
      }
    };

    fetchOnlineCharacters();
  }, []);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleAccountNameChange = (e) => setAccountName(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormError("Invalid email format");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+'#-])[A-Za-z\d@$!%*?&+'#-]{8,}$/;
    if (!passwordRegex.test(password)) {
      setFormError(
        "Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }

    setFormError("");

    try {
      let response = await fetch("/api/auth/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, username: accountName, password }),
      });

      if (response.status == 404 || response.status == 304) {
        response = await fetch("http://localhost:4000/auth/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, username: accountName, password }),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message);
        return;
      }

      const resultData = await response.json();

      if (
        resultData.result &&
        (resultData.result.faultCode || resultData.result.faultString)
      ) {
        setErrorMessage(
          resultData.result.faultString || "An unknown error occurred"
        );
        return;
      }

      alert("Account created successfully");
      // Reset the form
      setEmail("");
      setAccountName("");
      setPassword("");
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("account");
    sessionStorage.removeItem("verifier");
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="App">
      <div className="hero" style={{ backgroundImage: `url(${Hero})` }}>
        <img src={Title} alt="Title Banner" className="title-banner" />
        <Link to="home" smooth={true} duration={500}>
          <button className="learn-more">Learn More</button>
        </Link>
      </div>

      <header className="sticky-header">
        <nav>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
          <Link to="connection" smooth={true} duration={500}>
            Connection
          </Link>
          <Link to="info" smooth={true} duration={500}>
            Info
          </Link>
          <Link to="register" smooth={true} duration={500}>
            Register
          </Link>
          {isLoggedIn ? (
            <>
              <RouterLink to="/shop">Shop</RouterLink>
              <RouterLink to="/account">Account</RouterLink>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </>
          ) : (
            <RouterLink to="/login">Login</RouterLink>
          )}
        </nav>
      </header>

      <section id="home">
        <h1>Welcome to Wrath Reborn: Phoenix</h1>
        <p>
          Welcome to Wrath Reborn: Phoenix, the latest and most ambitious
          chapter in our server's storied history. Our journey began with Wrath
          Classic, a faithful recreation of the beloved World of Warcraft
          expansion. However, as time passed, the server faced challenges and
          eventually faded away.
        </p>
        <p>
          Refusing to let our community's passion die, we rose from the ashes
          with Wrath Reborn. This new incarnation brought with it a wealth of
          custom content, breathing fresh life into the game. From unique quests
          and dungeons to exciting new features, Wrath Reborn quickly became a
          favorite among players. However, with growth came the inevitable
          strain on performance, leading to bogged-down gameplay experiences.
        </p>
        <p>
          Determined to provide the best possible environment, we made the tough
          decision to perform a complete reset. Thus, Wrath Reborn: Phoenix was
          born. This ultimate reset aims to deliver a smoother, more
          exhilarating adventure, combining the rich history of our server with
          the highest standards of performance and innovation.
        </p>
        <p>
          Join us as we embark on this new era. Experience the thrill of Wrath
          Reborn: Phoenix, where the legacy of our past fuels the excitement of
          our future.
        </p>
        <a
          href="https://discord.gg/2weK6BBwvG"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join our Discord
        </a>
      </section>

      <section id="connection">
        <h2>Connection Info</h2>
        <p>Do you have the World of Warcraft client?</p>
        <p>
          If not, you can download it{" "}
          <a
            href="https://zremax.com/blog/wotlk-3-3-5-client-download-wrath-of-the-lich-king-client"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          Once you have the client, set your realmlist by following these steps:
        </p>
        <ol>
          <li>Go to your World of Warcraft directory.</li>
          <li>
            Navigate to the <strong>Data</strong> folder.
          </li>
          <li>
            Open the folder for your locale (most likely <strong>enUS</strong>{" "}
            or <strong>enGB</strong>).
          </li>
          <li>
            Find the file named <strong>realmlist.wtf</strong> and open it with
            a text editor.
          </li>
          <li>
            Replace the contents with:{" "}
            <code>set realmlist wrath-reborn.com</code>
          </li>
        </ol>
        <p>You're now ready to join Wrath Reborn: Phoenix!</p>
      </section>

      <section id="info" className="info-section">
        <div className="features">
          <h2>Features</h2>
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feat) => (
                <tr>
                  <td>{feat.name}</td>
                  <td>{feat.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <OnlineCharacters onlineCharacters={onlineCharacters} />
      </section>

      <section id="register" className="register-section">
        <h2>Register</h2>
        {errorMessage ? (
          <p className="error-message">{errorMessage}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </label>
            <label>
              Account Name:
              <input
                type="text"
                name="accountName"
                value={accountName}
                onChange={handleAccountNameChange}
                required
              />
            </label>
            <label>
              Password:
              <input
                type="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </label>
            {formError && <p className="form-error">{formError}</p>}
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        )}
        <button
          className="forgot-password-button"
          onClick={async () => {
            try {
              // First attempt: Send the request to the primary URL
              let response = await fetch("/api/auth/forgot-password", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ accountName }),
              });

              // If the response is 404, try the fallback local URL
              if (response.status === 404 || response.status !== 200) {
                response = await fetch(
                  "http://localhost:4000/auth/forgot-password",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ accountName }),
                  }
                );
              }

              // Process the response
              const data = await response.json();

              if (response.status === 200) {
                alert(
                  data.message ||
                    "Password reset successfully! Check your associated email."
                );
              } else {
                alert(data.message || "Failed to reset password.");
              }
            } catch (error) {
              console.error("Error resetting password:", error);
              alert("An error occurred while resetting the password.");
            }
          }}
        >
          Forgot password
        </button>
      </section>
      <footer className="footer">
        <p>© 2024 Wrath Reborn. All rights reserved.</p>
        <a
          href="https://discord.gg/2weK6BBwvG"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join our Discord
        </a>
      </footer>
    </div>
  );
}

export default App;

const OnlineCharacters = ({ onlineCharacters }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;

  const sortedCharacters = [...onlineCharacters].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedCharacters = sortedCharacters.slice(
    startIndex,
    startIndex + rowsPerPage
  );
  const totalPages = Math.ceil(onlineCharacters.length / rowsPerPage);

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const buttonStyles = {
    padding: "5px 10px",
    margin: "0 5px",
    backgroundColor: "#444",
    color: "#ddd",
    border: "1px solid #555",
    cursor: "pointer",
  };

  const disabledButtonStyles = {
    ...buttonStyles,
    backgroundColor: "#333",
    cursor: "not-allowed",
  };

  return (
    <div className="online-characters">
      <h3>Online Characters ({onlineCharacters.length})</h3>
      {onlineCharacters.length === 0 ? (
        <p>
          Unfortunately, no characters are online right now. Please check back
          later!
        </p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>
                  Name
                </th>
                <th onClick={() => handleSort("level")}>
                  Level
                </th>
                <th onClick={() => handleSort("race")}>
                  Race
                </th>
                <th onClick={() => handleSort("class")}>
                  Class
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedCharacters.map((char, index) => (
                <tr key={index}>
                  <td>{char.name}</td>
                  <td>{char.level}</td>
                  <td>{char.race}</td>
                  <td>{char.class}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination" style={{marginTop: '10px'}}>
            <button
              style={currentPage === 1 ? disabledButtonStyles : buttonStyles}
              onClick={() => handlePageChange("prev")}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              style={
                currentPage === totalPages ? disabledButtonStyles : buttonStyles
              }
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};
